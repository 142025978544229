@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(249, 249, 249) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-bar{
  /* background-image: url('http://www.puertadeoro.org/wp-content/uploads/2018/04/bg-proyectos.jpg?id=96') !important; */
  /* background-position-y: center; */
  height: 48px;
  text-align: center !important;
  display: block !important;
  background: #011d34;
}

.navbar-brand{
  margin-top: -9px;
}
.flat-button-file{
  padding: 4px 10px;
  border-style: solid !important;
  border-color: black !important;
}

.flat-button-file:hover{
  background-color: black;
  color: white;
  cursor: pointer;
}

.img-file{
  display: block;
  width: 100%;
  height: 100%;
}
.CSS
.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.form {
  width: 450px;
}

.form-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.badge_tags{
  font-size: 90% !important;
  margin: 2px;
  background-color: #6c757d;
  color: aliceblue;
}

.box_form{
  background-color: white;
  /* box-shadow: 0 0 1em;] */
}
.badge-primary{
  background-color: #007bff !important;
}